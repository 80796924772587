import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Actions as ActionsSettings } from "@/store/enums/SettingsEnum";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/dashboard/",
        component: () => import("@/layout/Layout.vue"),
        beforeEnter: (to, from, next) => {
            // console.log("1 isUserAuthenticated? " + store.getters.isUserAuthenticated);
            // console.log("1 isSSO " + store.getters.currentSettings.is_sso_activated);
            Promise.all([
                store.dispatch(Actions.GET_USER),
                store.dispatch(ActionsSettings.GET_SETTINGS)
            ])
                .then(() => {
                    if (!store.getters.isUserAuthenticated) {
                        // console.log("SSO Activated? " + store.getters.currentSettings.is_sso_activated)
                        if (!store.getters.currentSettings.is_sso_activated) {
                            // console.log("SSO inactive");
                            router.push({ name: "sign-in" });
                            next();
                        } else {
                            console.log("SSO active");
                            router.push({ name: "home-sso" });
                            next(false);
                        }
                    } else {
                        next();
                    }
                })
                .catch(() => {
                    router.push({ name: "sign-in" });
                }
                );
        },
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import("@/views/Dashboard.vue"),
            },
            {
                path: "/users-detail/:user_id/",
                name: "usersdetail",
                component: () => import("@/views/UsersDetail.vue"),
            },
            {
                path: "/users/",
                name: "users",
                component: () => import("@/views/Users.vue"),
            },
            {
                path: "/attendance-monitoring/",
                name: "attendance-monitoring",
                component: () => import("@/views/AttendanceMonitoring.vue"),
            },
            {
                path: "/drive/",
                name: "drive",
                component: () => import("@/views/Drive.vue"),
            },
            {
                path: "/surveys/",
                name: "surveys",
                component: () => import("@/views/Surveys.vue"),
            },
            {
                path: "/surveys/add/",
                name: "surveyAdd",
                component: () => import("@/views/SurveyAdd.vue"),
            },
            {
                path: "/drive/:id/",
                name: "driveDetail",
                component: () => import("@/views/DriveDetail.vue"),
            },
            {
                path: "/entities/",
                name: "entities",
                component: () => import("@/views/Entities.vue"),
            },
            {
                path: "/results/",
                name: "results",
                component: () => import("@/views/Results.vue"),
            },
            {
                path: "/results/specific/",
                name: "specific-report",
                component: () => import("@/views/SpecificReport.vue"),
            },
            {
                path: "/ideas-detail/:category_id/",
                name: "ideasdetail",
                component: () => import("@/views/IdeasDetail.vue"),
            },
            {
                path: "/ideas/",
                name: "ideas",
                component: () => import("@/views/Ideas.vue"),
            },
            {
                path: "/roadmap/",
                name: "roadmap",
                component: () => import("@/views/RoadMap.vue"),
            },
            {
                path: "/add-roadmap",
                name: "add-roadmap",
                component: () => import("@/views/AddRoadmap.vue"),
            },
            {
                path: "/roadmap/related-roadmap/",
                name: "related-roadmap",
                component: () => import("@/views/RelatedRoadMap.vue"),
            },
            {
                path: "/roadmap/related-roadmap/user-roadmaps/:user_id/",
                name: "user-roadmaps",
                component: () => import("@/views/UserRoadmap.vue"),
            },
            {
                path: "/useful-training/",
                name: "useful-training",
                component: () => import("@/views/UsefulTraining.vue"),
            },
            {
                path: "/notifications/",
                name: "notifications",
                component: () => import("@/views/NotificationList.vue"),
            },
            {
                path: "/statistics/users/",
                name: "statistics-users",
                component: () => import("@/views/Users.vue"),
            },
            {
                path: "/statistics/roadmaps/",
                name: "statistics-roadmaps",
                component: () => import("@/views/statistics/Roadmaps.vue"),
            },
        ],
    },
    {
        path: "/administration/",
        // redirect: "/administration/",
        component: () => import("@/layout/Layout.vue"),
        beforeEnter: (to, from, next) => {
            store.dispatch(Actions.GET_USER).then(() => {
                if (!store.getters.isUserAuthenticated) {
                    router.push({ name: "sign-in" });
                    next(false);
                } else if (store.getters.isUserAuthenticated && store.getters.currentUser.user.userprofile.user_type.position !== 1) {
                    next(false);
                } else {
                    next();
                }
            });
        },
        children: [
            {
                path: "/administration/settings/",
                name: "administration-settings",
                component: () => import("@/views/administration/Settings.vue"),
            },
            {
                path: "/administration/access/",
                name: "administration-access",
                component: () => import("@/views/administration/Access.vue"),
            },
            {
                path: "/administration/documents/categories/",
                name: "administration-documentcategories",
                component: () => import("@/views/administration/DocumentCategories.vue"),
            },
            {
                path: "/administration/documents/categories/add/",
                name: "administration-add-documentcategories",
                component: () => import("@/views/administration/AddDocumentCategories.vue"),
            },
            {
                path: "/administration/documents/documents/",
                name: "administration-documents",
                component: () => import("@/views/administration/Documents.vue"),
            },
            {
                path: "/administration/documents/documents/add",
                name: "administration-add-documents",
                component: () => import("@/views/administration/AddDocument.vue"),
            },
            {
                path: "/administration/users/",
                name: "administration-users",
                component: () => import("@/views/administration/Users.vue"),
            },
            {
                path: "/administration/roadmapcategory/",
                name: "administration-roadmapcategory",
                component: () => import("@/views/administration/RoadMapCategory.vue"),
            },
            {
                path: "/administration/ideabox/",
                name: "administration-ideabox",
                component: () => import("@/views/administration/IdeaBox.vue"),
            },
            {
                path: "/administration/usefultraining/",
                name: "administration-usefultraining",
                component: () => import("@/views/administration/UsefulTrainings.vue"),
            },
            {
                path: "/administration/surveys/questions/",
                name: "administration-survey-questions",
                component: () => import("@/views/administration/SurveyQuestions.vue"),
            },
            {
                path: "/administration/surveys/list/",
                name: "administration-survey-list",
                component: () => import("@/views/administration/SurveyList.vue"),
            }, {
                path: "/administration/surveys/question-tags/",
                name: "administration-survey-questiontags",
                component: () => import("@/views/administration/SurveyQuestionTag.vue"),
            }, {
                path: "/administration/surveys/languages/",
                name: "administration-survey-languages",
                component: () => import("@/views/administration/SurveyLanguage.vue"),
            },
            {
                path: "/administration/campaigns/",
                name: "administration-campaigns",
                component: () => import("@/views/administration/Campaign.vue"),
            },
            {
                path: "/administration/results/",
                name: "administration-results",
                component: () => import("@/views/administration/Results.vue"),
            }

        ],
    },
    {
        path: "/",
        component: () => import("@/layout/Auth.vue"),
        beforeEnter: (to, from, next) => {
            if (to.name === 'auth-callback') {
                if (store.getters.currentSettings.is_sso_activated) {
                    // console.log("to callback")
                    next();
                } else {
                    // console.log("to sign-in")
                    router.push({ name: "sign-in" });
                }
            } else {
                Promise.all([
                    store.dispatch(Actions.GET_USER),
                    store.dispatch(ActionsSettings.GET_SETTINGS)
                ])
                    .then(() => {
                        if (!store.getters.isUserAuthenticated) {
                            // console.log("SSO Activated? " + store.getters.currentSettings.is_sso_activated)
                            if (!store.getters.currentSettings.is_sso_activated) {
                                // console.log("SSO inactive");
                                next();
                            } else {
                                // console.log("SSO active");
                                if(from.name !== 'home-sso'){
                                    //console.log("go to home-sso");
                                    router.push({ name: "home-sso" });
                                }else{
                                    //console.log("go to next : sign-in");
                                    next();
                                }
                            }
                        } else {
                            next();
                        }
                    })
                    .catch(() => {
                        router.push({ name: "sign-in" });
                    }
                    );
            }
        },
        children: [
            {
                path: "/sign-in",
                name: "sign-in",
                component: () => import("@/views/authentication/SignIn.vue"),
            },
            {
                path: "/sign-up",
                name: "sign-up",
                component: () => import("@/views/authentication/SignUp.vue"),
            },
            {
                path: "/password-reset",
                name: "password-reset",
                component: () => import("@/views/authentication/PasswordReset.vue"),
            },
            {
                path: "/change-password/:token",
                name: "change-password",
                component: () => import("@/views/authentication/ChangePassword.vue"),
            }
        ],
    },
    {
        path: "/",
        component: () => import("@/layout/Callback.vue"),
        beforeEnter: (to, from, next) => {
            //console.log("3 isUserAuthenticated? " + store.getters.isUserAuthenticated);
            //console.log("3 isSSO " + store.getters.currentSettings.is_sso_activated);
            if (to.name === 'auth-callback') {
                if (store.getters.currentSettings.is_sso_activated) {
                    //console.log(store.getters.currentSettings.is_sso_activated);
                    next();
                } else {
                    router.push({ name: "sign-in" });
                }
            } else {
                Promise.all([
                    store.dispatch(Actions.GET_USER),
                    store.dispatch(ActionsSettings.GET_SETTINGS)
                ])
                    .then(() => {
                        if (!store.getters.isUserAuthenticated) {
                            // console.log("SSO Activated? " + store.getters.currentSettings.is_sso_activated)
                            if (!store.getters.currentSettings.is_sso_activated) {
                                // console.log("SSO inactive");
                                //console.log("3 SSO inactive");
                                router.push({ name: "sign-in" });
                            } else {
                                //console.log("3 SSO active");
                                next();
                            }
                        } else {
                            console.log("3 else");
                            next();
                        }
                    })
                    .catch(() => {
                        //console.log("3 catch");
                        router.push({ name: "sign-in" });
                    }
                    );
            }
        },
        children: [
            {
                path: "/token-callback",
                name: "token_callback",
                component: () => import("@/views/authentication/Callback_saml.vue"),
            },
            {
                path: "/auth/callback",
                name: "auth-callback",
                component: () => import("@/views/authentication/Callback.vue"),
            },
            {
                path: "/logout",
                name: "auth-logout",
                component: () => import("@/views/authentication/Logout.vue"),
            },
            {
                path: "/home/sso",
                name: "home-sso",
                component: () => import("@/views/authentication/HomeSso.vue"),
            },
        ],
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/Error404.vue"),
    },
    {
        path: "/500",
        name: "500",
        component: () => import("@/views/Error500.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(() => {
    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);
    store.dispatch(Actions.VERIFY_AUTH);
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

export default router;
